import React from 'react';
import { connect } from 'react-redux';
import ModalComponent from '../components/ModalComponent';
import { hideModal } from '../actions/modal';
import propDefs from '../proptypes';

export const HoursModalContainer = (props) => {
	const { modal, onHideModal, ...rest } = props;
	return (
		<ModalComponent
			show={modal ? modal.showModal : false}
			onHide={onHideModal}
			{...rest}
		/>
	);
};

HoursModalContainer.defaultProps = {
	hours: propDefs.defaults.hours,
	modal: propDefs.defaults.modal,
	allHoursDepts: propDefs.defaults.allHoursDepts,
	allHoursLabelIds: propDefs.defaults.allHoursLabelIds,
	hoursListLineHeight: propDefs.defaults.hoursListLineHeight,
	hoursTextAlignment: propDefs.defaults.hoursTextAlignment,
	showCurrentDayOnly: propDefs.defaults.showCurrentDayOnly
};

HoursModalContainer.propTypes = {
	hours: propDefs.settings.hours,
	modal: propDefs.settings.modal,
	onHideModal: propDefs.settings.onHideModal.isRequired,
	allHoursDepts: propDefs.settings.allHoursDepts,
	allHoursLabelIds: propDefs.settings.allHoursLabelIds,
	hoursListLineHeight: propDefs.settings.hoursListLineHeight,
	hoursTextAlignment: propDefs.settings.hoursTextAlignment,
	showCurrentDayOnly: propDefs.settings.showCurrentDayOnly
};

const mapStateToProps = (state) => {
	return {
		hours: state.hours,
		modal: state.modal
	};
};

const mapDispatchToProps = (dispatch) => ({
	onHideModal: () => {
		dispatch(hideModal());
	}
});

const HoursModal = connect(
	mapStateToProps,
	mapDispatchToProps
)(HoursModalContainer);

export default HoursModal;
