import React from 'react';
import { Modal } from 'react-bootstrap';
import { Common } from 'ws-scripts/modules/common';
import { Hours } from 'wsm-hours';

import propDefs from '../proptypes';

const ModalComponent = (props) => {
	const renderDelegate = ({ labels }) => {
		const { show, onHide, ...rest } = props;
		return (
			<Modal show={show} className="modal-in-dialog" onHide={onHide}>
				<Modal.Header closeButton>
					<Modal.Title>{labels.get('HOURS')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Hours isModal {...rest} />
				</Modal.Body>
			</Modal>
		);
	};

	return <Common render={renderDelegate} />;
};

ModalComponent.defaultProps = {
	show: propDefs.defaults.show,
	hours: propDefs.defaults.hours,
	onHide: propDefs.defaults.onHide,
	allHoursDepts: propDefs.defaults.allHoursDepts,
	allHoursLabelIds: propDefs.defaults.allHoursLabelIds,
	hoursListLineHeight: propDefs.defaults.hoursListLineHeight,
	hoursTextAlignment: propDefs.defaults.hoursTextAlignment,
	showCurrentDayOnly: propDefs.defaults.showCurrentDayOnly
};

ModalComponent.propTypes = {
	show: propDefs.settings.show,
	onHide: propDefs.settings.onHide,
	hours: propDefs.settings.hours,
	allHoursDepts: propDefs.settings.allHoursDepts,
	allHoursLabelIds: propDefs.settings.allHoursLabelIds,
	hoursListLineHeight: propDefs.settings.hoursListLineHeight,
	hoursTextAlignment: propDefs.settings.hoursTextAlignment,
	showCurrentDayOnly: propDefs.settings.showCurrentDayOnly
};

export default ModalComponent;
