export function setTrackingEvent(widgetName, eventName) {
	if (typeof CustomEvent === 'function') {
		const evt = new CustomEvent('trackEvent', {
			detail: {
				widgetName,
				eventName,
				data: 'ws-hours'
			}
		});

		document.dispatchEvent(evt);
	}
}
