import React from 'react';
import { connect } from 'react-redux';
import { Hours } from 'wsm-hours';
import HoursModal from './HoursModal';
import { showModal } from '../actions/modal';
import { prefsToProps } from '../helpers/utility';
import SeeAllHoursButton from '../components/SeeAllHoursButton';

import propDefs from '../proptypes';

const getApp = (department, hours, prefs, getModalDisplay) => {
	const classes = ['clearfix'];

	// Pass a subset of prefs to hours module as props
	const {
		'account.id': _accountId,
		'department.id': _departmentId,
		showSunday: _showSunday,
		shrinkIdenticalHours: _shrinkIdenticalHours,
		...rest
	} = prefs;
	const convertedPrefs = prefsToProps(rest);

	if (prefs.showCurrentDayOnly === 'true') {
		classes.push('row');
	}

	if (hours && Object.keys(hours).length) {
		return (
			<div className={classes.join(' ')}>
				<Hours
					hours={hours}
					department={department}
					isModal={false}
					{...convertedPrefs}
				/>
				<SeeAllHoursButton showModalAction={getModalDisplay} />
				<HoursModal hours={hours} {...convertedPrefs} />
			</div>
		);
	}

	return null;
};

export const WidgetContainer = (props) => {
	let department = null;
	if (props.hours && Object.keys(props.hours).length) {
		const id = props.prefs['department.id'];
		department = id && props.hours[id] ? id : Object.keys(props.hours)[0];
	}

	return getApp(department, props.hours, props.prefs, props.getModalDisplay);
};

WidgetContainer.defaultProps = {
	hours: propDefs.defaults.hours,
	getModalDisplay: propDefs.defaults.getModalDisplay
};

WidgetContainer.propTypes = {
	hours: propDefs.settings.hours,
	prefs: propDefs.settings.prefs.isRequired,
	getModalDisplay: propDefs.settings.getModalDisplay
};

const mapStateToProps = (state) => {
	return {
		hours: state.hours,
		prefs: state.prefs
	};
};

const mapDispatchToProps = (dispatch) => ({
	getModalDisplay: () => {
		dispatch(showModal());
	}
});

const Widget = connect(mapStateToProps, mapDispatchToProps)(WidgetContainer);

export default Widget;
