import { SHOW_MODAL, HIDE_MODAL } from '../actions/constants';

export default function modal(state = {}, action = null) {
	if (!action.error && action.payload) {
		switch (action.type) {
			case SHOW_MODAL:
				return action.payload;

			case HIDE_MODAL:
				return action.payload;

			// no default
		}
	}

	return state;
}
