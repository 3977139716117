import { BUTTON_STYLES } from '../constants';

/**
 * Updates specific class names to include "btn-" prefix.
 * @param {string} styleClasses - button style class names set in "showDepartmentsViewAllLinkButtonStyle" widget preference.
 * @returns {array} - all styleClasses passed in, with specific ones updated to include a "btn-" prefix.
 */
export function setButtonStyleClasses(styleClasses) {
	if (typeof styleClasses !== 'string') return [];

	return styleClasses
		.split(' ')
		.map((currentClass) =>
			BUTTON_STYLES.includes(currentClass)
				? `btn-${currentClass}`
				: currentClass
		);
}
