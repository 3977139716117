import React from 'react';
import { usePrefs, useLabels } from 'wsm-common-data';
import { setClassNames } from 'ddc-classnames-js';
import { setButtonStyleClasses } from '../helpers/setButtonStyleClasses';
import propDefs from '../proptypes';

const SeeAllHoursButton = ({ showModalAction }) => {
	const labels = useLabels();
	const {
		showDepartmentsViewAllLink,
		showDepartmentsViewAllLinkButtonLayout,
		showDepartmentsViewAllLinkButtonClasses,
		showDepartmentsViewAllLinkButtonInverse,
		showDepartmentsViewAllLinkButtonSize,
		showCurrentDayOnly,
		showDepartmentsViewAllLinkButtonStyle
	} = usePrefs();

	if (!(showDepartmentsViewAllLink === 'true')) {
		return null;
	}

	const classNames = [showDepartmentsViewAllLinkButtonLayout];
	const wrapperClasses = ['text-center'];
	classNames.push(...showDepartmentsViewAllLinkButtonClasses.split(' '));
	if (showDepartmentsViewAllLinkButtonInverse === 'true') {
		classNames.push('btn-inverse');
	}
	if (
		showDepartmentsViewAllLinkButtonSize &&
		showDepartmentsViewAllLinkButtonSize !== 'default'
	) {
		classNames.push(`btn-${showDepartmentsViewAllLinkButtonSize}`);
	}

	if (showCurrentDayOnly === 'true') {
		wrapperClasses.push('col-md-7', 'col-lg-8', 'col-xs-12');
		classNames.push('py-0', 'pl-0');
	}

	return (
		<div className={setClassNames([...wrapperClasses])}>
			<button
				type="button"
				onClick={showModalAction}
				className={setClassNames([
					'btn',
					...classNames,
					...setButtonStyleClasses(
						showDepartmentsViewAllLinkButtonStyle
					)
				])}
			>
				{labels.get('SEE_ALL_DEPARTMENT_HOURS')}
			</button>
		</div>
	);
};

SeeAllHoursButton.propTypes = {
	showModalAction: propDefs.settings.getModalDisplay.isRequired
};

export default SeeAllHoursButton;
