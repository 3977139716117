import PropTypes from 'prop-types';

const hoursEntry = PropTypes.shape({
	day: PropTypes.string,
	open: PropTypes.string,
	close: PropTypes.string,
	timings: PropTypes.string
});

const propDefs = {
	defaults: {
		allHoursDepts: 'DEALERSHIP,PARTS,SERVICE',
		allHoursLabelIds:
			'DEALERSHIP_HOURS,PARTS_DEPT_HOURS,SERVICE_DEPT_HOURS',
		getModalDisplay: () => {
			return false;
		},
		heading: '',
		hours: {
			default: [],
			parts: [],
			service: []
		},
		hoursListLineHeight: 'condensed',
		hoursTextAlignment: 'left',
		modal: {
			showModal: false
		},
		onHide: () => {},
		show: false,
		isModal: false,
		showCurrentDayOnly: false
	},

	settings: {
		department: PropTypes.string,
		getModalDisplay: PropTypes.func,
		heading: PropTypes.string,
		onHideModal: PropTypes.func,
		hours: PropTypes.objectOf(PropTypes.arrayOf(hoursEntry)),
		hoursArray: PropTypes.array,
		modal: PropTypes.shape({
			showModal: PropTypes.bool
		}),
		onHide: PropTypes.func,
		prefs: PropTypes.shape({
			allHoursDepts: PropTypes.string,
			allHoursLabelIds: PropTypes.string,
			hoursListLineHeight: PropTypes.string,
			hoursTextAlignment: PropTypes.string,
			showCurrentDayOnly: PropTypes.string,
			showDepartmentsViewAllLink: PropTypes.string,
			showSunday: PropTypes.string,
			shrinkIdenticalHours: PropTypes.string,
			'department.id': PropTypes.string
		}),
		setHours: PropTypes.func,
		show: PropTypes.bool,
		showModal: PropTypes.bool,
		isModal: PropTypes.bool,
		allHoursDepts: PropTypes.string,
		allHoursLabelIds: PropTypes.string,
		hoursListLineHeight: PropTypes.string,
		hoursTextAlignment: PropTypes.string,
		showCurrentDayOnly: PropTypes.bool,
		showDepartmentsViewAllLink: PropTypes.bool
	},

	hoursEntry
};

export default propDefs;
