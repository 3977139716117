import { SET_HOURS } from '../actions/constants';

export default function hours(state = {}, action = null) {
	if (!action.error && action.payload) {
		switch (action.type) {
			case SET_HOURS:
				return action.payload;

			// no default
		}
	}

	return state;
}
