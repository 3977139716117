import { SHOW_MODAL, HIDE_MODAL } from './constants';
import { setTrackingEvent } from '../helpers/tracker';

export function showModal() {
	setTrackingEvent('dialog', 'open');

	return {
		type: SHOW_MODAL,
		payload: {
			showModal: true
		}
	};
}

export function hideModal() {
	setTrackingEvent('dialog', 'close');

	return {
		type: HIDE_MODAL,
		payload: {
			showModal: false
		}
	};
}
