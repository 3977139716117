// Convert string preferences to be used as prop values
export function prefsToProps(prefs) {
	const props = {};

	if (prefs && typeof prefs === 'object' && !Array.isArray(prefs)) {
		Object.keys(prefs).forEach((key) => {
			// Pass value through by default
			props[key] = prefs[key];

			if (
				!(
					typeof prefs[key] === 'string' ||
					prefs[key] instanceof String
				)
			) {
				// Preference values should be strings
				// This will also catch undefined and null values before proceeding wth string functions
				props[key] = null;
			} else {
				const upperPref = prefs[key].trim().toUpperCase();

				if (upperPref === 'TRUE' || upperPref === 'FALSE') {
					props[key] = upperPref === 'TRUE';
				} else if (upperPref === 'BLANK' || upperPref === '') {
					props[key] = null;
				}
			}
		});
	}

	return props;
}
